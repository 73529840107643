<template>
  <div :class="['flex flex-col', { 'animate-shakeError': hasError }]">
    <label :for="`date-picker-${name}`" class="uppercase text-sm font-semibold mb-1 flex items-center text-grey-800">
      <span>{{ label }}</span>
      <span v-if="optional" class="text-xs text-grey-600 normal-case">&nbsp;&bull;&nbsp;Optionnel</span>
    </label>
    <div
      :class="[
        'relative flex items-center bg-white py-1 px-3 w-[375px] h-[50px] shadow border-l-[5px] transition-[border,width] duration-300',
        hasError ? 'border-danger-400' : 'border-primary-600'
      ]"
    >
      <touch-date-picker
        v-model="input"
        class="border-none outline-none bg-transparent text-base ml-2 text-grey-700 flex-1 h-full w-full placeholder:font-light placeholder:text-grey-600 placeholder:text-sm placeholder:uppercase"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        language="fr"
        @change="$emit('change', input ? new Date(input) : null)"
      ></touch-date-picker>
      <fa-icon v-if="icon" :icon="icon" class="text-xl text-primary-900 ml-2 mr-1"></fa-icon>
    </div>
    <div
      :class="[
        'origin-top-left transition-all duration-300 ease-in-out text-danger-500 py-1 px-5 bg-danger-100 border-l-[5px] border-danger-500 shadow max-w-[375px]',
        hasError ? 'text-base mt-5 scale-y-100 py-2' : 'text-[0] scale-y-0'
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import fieldMixin from './mixins/field.js'
// import Datepicker from 'vuejs-datepicker'
import TouchDatePicker from '@/components/common/form/TouchDatePicker'
import { fr } from 'vuejs-datepicker/dist/locale'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  mixins: [fieldMixin],
  props: {
    value: [Date, String],
    inline: {
      type: Boolean,
      default: false
    },
    format: {
      type: [String, Function],
      default: 'dd MMMM yyyy'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: this.value,
      fr: fr
    }
  },
  watch: {
    value(val) {
      this.input = val
    }
  },
  components: {
    // Datepicker,
    TouchDatePicker
  }
}
</script>
