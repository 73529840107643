export default {
  props: {
    id: String,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: String,
    icon: [Object, Array, String],
    optional: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocused: false
    }
  }
}
