<template>
  <div class="modal-container z-[997] flex flex-col justify-center items-center fixed inset-0" v-if="show">
    <div class="overlay absolute inset-0 bg-black/50"></div>
    <div
      :class="[
        'modal flex flex-col z-[997] w-11/12 min-w-[350px] max-w-5xl min-h-[300px] max-h-[95vh] overflow-auto bg-white shadow rounded-b-sm border-t-[6px]',
        {
          'border-primary-500': type === 'primary',
          'border-accent-500': type === 'accent',
          'border-info-400': type === 'info',
          'border-success-400': type === 'success',
          'border-warning-400': type === 'warning',
          'border-danger-400': type === 'danger'
        }
      ]"
      v-if="show"
    >
      <div class="body m-12 flex flex-1">
        <div
          :class="[
            'icon text-2xl mx-3 rounded-full h-10 flex-shrink-0 flex-grow-0 basis-10 flex justify-center items-center relative z-[998] before:absolute before:z-[997] before:inset-[18%] before:rounded-full',
            {
              'bg-primary-300 before:bg-primary-500': type === 'primary',
              'bg-accent-300 before:bg-accent-500': type === 'accent',
              'bg-info-300 before:bg-info-500': type === 'info',
              'bg-success-300 before:bg-success-500': type === 'success',
              'bg-warning-300 before:bg-warning-500': type === 'warning',
              'bg-danger-300 before:bg-danger-500': type === 'danger'
            }
          ]"
          v-if="confirm"
        >
          <fa-icon class="text-white z-[999]" :icon="icon"></fa-icon>
        </div>
        <div class="content flex-1">
          <h1 class="text-xl text-grey-800 font-medium mt-2">{{ title }}</h1>
          <slot></slot>
        </div>
      </div>
      <div class="actions py-4 px-6 bg-grey-100 text-right space-x-4" v-if="confirm || cancel">
        <button
          v-if="cancel"
          class="cancel-button bg-none border-none outline-none font-semibold text-sm py-1 px-5 min-w-[100px] h-10 rounded cursor-pointer text-grey-800 hover:bg-grey-300"
          @click="onCancel"
        >
          {{ cancelLabel }}
        </button>
        <button
          v-if="confirm"
          :class="[
            'confirm-button bg-none border-none outline-none font-semibold text-sm py-1 px-5 min-w-[100px] h-10 rounded cursor-pointer',
            {
              'bg-primary-100 text-primary-600 hover:bg-primary-200': type === 'primary',
              'bg-accent-100 text-accent-700 hover:bg-accent-200': type === 'accent',
              'bg-info-100 text-info-500 hover:bg-info-200': type === 'info',
              'bg-success-100 text-success-500 hover:bg-success-200': type === 'success',
              'bg-warning-100 text-warning-500 hover:bg-warning-200': type === 'warning',
              'bg-danger-100 text-danger-500 hover:bg-danger-200': type === 'danger'
            }
          ]"
          @click="onConfirm"
        >
          {{ confirmLabel }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faExclamationCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faInfoCircle, faExclamationCircle, faCheckCircle, faTimesCircle)

export default {
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info',
      validator: val => ['primary', 'accent', 'info', 'success', 'warning', 'danger'].find(t => val === t)
    },
    title: {
      type: String,
      default: 'Êtes-vous sûr ?'
    },
    confirm: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: Boolean,
      default: true
    },
    confirmLabel: {
      type: String,
      default: 'Je confirme'
    },
    cancelLabel: {
      type: String,
      default: 'Non'
    }
  },
  computed: {
    icon() {
      return {
        primary: 'check-circle',
        accent: 'check-circle',
        info: 'info-circle',
        success: 'check-circle',
        warning: 'exclamation-circle',
        danger: 'times-circle'
      }[this.type]
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
      this.onCancel()
    },
    onCancel() {
      this.$emit('change', false)
    },
    checkKey(e) {
      if (typeof e.key === 'string' && e.key.toLowerCase() === 'escape') {
        this.onCancel()
      }
    },
    checkOutsideClick(e) {
      if (e.target.classList.contains('overlay')) {
        this.onCancel()
      }
    }
  },
  created() {
    if (this.cancel) {
      document.addEventListener('keyup', this.checkKey)
      document.addEventListener('click', this.checkOutsideClick)
    }
  },
  destroyed() {
    if (this.cancel) {
      document.removeEventListener('keyup', this.checkKey)
      document.removeEventListener('click', this.checkOutsideClick)
    }
  }
}
</script>
