var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"enter-active-class":"transform ease-out duration-300 transition","enter-class":"translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2","enter-to-class":"translate-y-0 opacity-100 sm:translate-x-0","leave-active-class":"transition ease-in duration-100","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[(_vm.show)?_c('div',{class:[
      'min-w-[288px] max-w-xl min-h-[48px] max-h-20 py-3.5 px-5 text-base font-semibold flex items-center fixed top-12 right-6 z-[1000] rounded border-2 border-danger-300 shadow',
      {
        'bg-info-100 border-info-300 text-info-600': _vm.type === 'info',
        'bg-success-100 border-success-300 text-success-600': _vm.type === 'success',
        'bg-warning-100 border-warning-300 text-warning-600': _vm.type === 'warning',
        'bg-danger-100 border-danger-300 text-danger-600': _vm.type === 'error'
      }
    ]},[_c('div',{class:[
        'icon-wrapper mr-2 shrink-0 w-9 h-9 flex items-center justify-center rounded-full',
        ,
        {
          'bg-info-300': _vm.type === 'info',
          'bg-success-300': _vm.type === 'success',
          'bg-warning-300': _vm.type === 'warning',
          'bg-danger-300': _vm.type === 'error'
        }
      ]},[_c('div',{class:[
          'icon-background w-3/4 h-3/4 text-center pt-0.5 bg-white rounded-full border-2',
          {
            'border-info-500': _vm.type === 'info',
            'border-success-500': _vm.type === 'success',
            'border-warning-500': _vm.type === 'warning',
            'border-danger-500': _vm.type === 'error'
          }
        ]},[_c('fa-icon',{class:[
            'icon text-base',
            {
              'text-info-500': _vm.type === 'info',
              'text-success-500': _vm.type === 'success',
              'text-warning-500': _vm.type === 'warning',
              'text-danger-500': _vm.type === 'error'
            }
          ],attrs:{"icon":_vm.icon}})],1)]),_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }