import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import Registration from './views/Registration.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'registration',
      component: Registration,
      meta: {
        title: 'Renouvellement du Certiphyto : pré-inscription'
      }
    },
    {
      path: '/inscription/:key',
      name: 'inscription',
      component: () => import(/* webpackChunkName: "inscription" */ './views/Status.vue'),
      meta: {
        title: 'Renouvellement du Certiphyto : état de votre inscription'
      }
    },
    {
      path: '/admin',
      component: Dashboard,
      meta: {
        title: 'Renouvellement du Certiphyto : administration'
      },
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "registered-list" */ './views/RegisteredList.vue')
        },
        {
          path: 'resultats',
          component: () => import(/* webpackChunkName: "results" */ './views/Results.vue')
        },
        {
          path: 'archives',
          component: () => import(/* webpackChunkName: "archives" */ './views/Archives.vue')
        },
        {
          path: 'donnees',
          component: () => import(/* webpackChunkName: "donnees" */ './views/Data.vue')
        },
        {
          path: 'journaux',
          component: () => import(/* webpackChunkName: "journaux" */ './views/Logs.vue')
        }
      ]
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }
  next()
})

export default router
