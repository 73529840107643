<template>
  <component
    :is="tag"
    :type="buttonType"
    :href="link"
    :target="newWindow ? '_blank' : null"
    :class="[
      'inline-flex text-white font-semibold text-sm uppercase p-1 min-w-[100px] h-[40px] justify-between items-center shadow focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75',
      {
        'bg-primary-500 hover:bg-primary-400 focus:ring-primary-500': type === 'primary',
        'bg-accent-600 hover:bg-accent-400 focus:ring-accent-500': type === 'secondary',
        'bg-info-500 hover:bg-info-400 focus:ring-info-500': type === 'info',
        'bg-success-500 hover:bg-success-400 focus:ring-success-500': type === 'success',
        'bg-warning-600 hover:bg-warning-500 focus:ring-warning-500': type === 'warning',
        'bg-danger-500 hover:bg-danger-400 focus:ring-danger-500': type === 'error',
        'bg-grey-600 hover:bg-grey-500 focus:ring-grey-500': type === 'common',
        'cursor-not-allowed': disabled
      }
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <span class="mx-3">
      <slot></slot>
    </span>
    <div
      v-if="icon"
      :class="[
        'text-white h-6 w-6 rounded-full text-center font-normal text-base flex items-center justify-center',
        {
          'bg-primary-700': type === 'primary',
          'bg-accent-900': type === 'secondary',
          'bg-info-600': type === 'info',
          'bg-success-600': type === 'success',
          'bg-warning-700': type === 'warning',
          'bg-danger-600': type === 'error',
          'bg-grey-700': type === 'common'
        }
      ]"
    >
      <fa-icon :icon="icon"></fa-icon>
    </div>
    <input
      v-if="upload"
      type="file"
      class="hidden"
      ref="upload_file"
      :multiple="multiUpload"
      :accept="uploadAccept"
      @input="$emit('upload', $event.target.files)"
    />
  </component>
</template>
<script>
export default {
  props: {
    icon: [String, Object, Array],
    type: {
      type: String,
      default: 'primary',
      validator: type => ['primary', 'secondary', 'success', 'error', 'info', 'warning', 'common'].includes(type)
    },
    link: {
      type: String
    },
    newWindow: {
      type: Boolean,
      default: false
    },
    upload: {
      type: Boolean,
      default: false
    },
    multiUpload: {
      type: Boolean,
      default: false
    },
    uploadAccept: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag() {
      return this.link ? 'a' : 'button'
    },
    buttonType() {
      return this.link ? null : this.submit ? 'submit' : 'button'
    }
  },
  methods: {
    onClick(event) {
      if (this.upload) {
        this.$refs.upload_file.click()
      }
      this.$emit('click', event)
    }
  }
}
</script>
