<template>
  <div class="flex items-center px-4 h-full cursor-pointer">
    <div class="flex flex-col items-end">
      <div class="text-primary-200 font-medium">{{ user.name }}</div>
      <div class="text-sm text-grey-300 self-end" v-if="user.email">{{ user.email }}</div>
    </div>
    <div class="p-1 h-10 w-10 flex justify-center items-center rounded-full bg-white ml-2 border border-grey-200">
      <img src="/images/user.png" alt="Avatar" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  }
}
</script>
