import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
import App from './App.vue'
import router from './router'
import store from './utils/store'
import http from './utils/http'
import './styles.css'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-icon-layers', FontAwesomeLayers)
Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox)

Vue.prototype.$http = http
Vue.prototype.$store = store

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
