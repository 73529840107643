<template>
  <div class="flex flex-col justify-center items-center flex-1" v-if="loading">
    <spring-spinner :animation-duration="1500" :size="120" color="rgb(142, 37, 98)" />
  </div>
  <div class="flex flex-col flex-1" v-else>
    <nav
      class="sticky top-0 inset-x-0 z-[99] flex items-center h-[50px] px-8 bg-grey-900 text-white border-b-[3px] border-accent-500"
    >
      <router-link
        exact
        to="/admin"
        tag="div"
        class="p-4 cursor-pointer hover:bg-accent-500 h-full flex items-center space-x-2"
        active-class="bg-accent-500"
      >
        <fa-icon icon="user-alt" class="mr-1"></fa-icon>
        <span>Inscrits</span>
      </router-link>
      <router-link
        to="/admin/resultats"
        tag="div"
        class="p-4 cursor-pointer hover:bg-accent-500 h-full flex items-center space-x-2"
        active-class="bg-accent-500"
      >
        <fa-icon icon="clipboard-list" class="mr-1"></fa-icon>
        <span>Résultats</span>
      </router-link>
      <router-link
        to="/admin/archives"
        tag="div"
        class="p-4 cursor-pointer hover:bg-accent-500 h-full flex items-center space-x-2"
        active-class="bg-accent-500"
      >
        <fa-icon icon="archive" class="mr-1"></fa-icon>
        <span>Archivés</span>
      </router-link>
      <router-link
        to="/admin/donnees"
        tag="div"
        class="p-4 cursor-pointer hover:bg-accent-500 h-full flex items-center space-x-2"
        active-class="bg-accent-500"
      >
        <fa-icon icon="database" class="mr-1"></fa-icon>
        <span>Données</span>
      </router-link>
      <router-link
        to="/admin/journaux"
        tag="div"
        class="p-4 cursor-pointer hover:bg-accent-500 h-full flex items-center space-x-2"
        active-class="bg-accent-500"
      >
        <fa-icon :icon="['far', 'list-alt']" class="mr-1"></fa-icon>
        <span>Journaux</span>
      </router-link>
      <user-identity class="ml-auto p-4 hover:bg-accent-500" v-if="user" :user="user"></user-identity>
    </nav>
    <main class="p-8 flex-1">
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import UserIdentity from '@/components/common/UserIdentity'
import { SpringSpinner } from 'epic-spinners'
import { faUserAlt, faClipboardList, faArchive, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faUserAlt, faClipboardList, faArchive, faDatabase, faListAlt)

export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  async created() {
    const [err, ret] = await this.$http.get('user')
    if (!err) {
      this.loading = false
      this.$store.setUser(ret.data.user)
    }
  },
  components: {
    SpringSpinner,
    UserIdentity
  }
}
</script>
