<template>
  <div class="date-container relative flex flex-1 h-full">
    <input
      type="text"
      readonly
      @focus="showCalendar"
      :value="outputDate"
      :placeholder="placeholder"
      v-bind="$attrs"
      :id="id + '-output'"
      :class="inputClass"
    />
    <input
      type="hidden"
      @change="inputChangeDesktop"
      v-model="modelValue"
      :name="name"
      :id="id"
      :data-vv-as="dataVvAs"
    />

    <div class="date-select absolute font-normal z-[15]" v-show="calendarOpen">
      <div
        class="popup relative flex flex-col w-[302px] h-[151px] p-0.5 bg-white border border-grey-300 shadow box-content"
        onwheel="event.preventDefault()"
      >
        <span
          class="tip absolute left-3 -top-3 z-[5] w-0 h-0 border-l-[10px] border-r-[10px] border-b-[12px] border-b-grey-300 border-l-transparent border-r-transparent before:absolute before:-left-2 before:top-0.5 before:z-20 before:w-0 before:h-0 before:border-l-8 before:border-r-8 before:border-b-[10px] before:border-b-white before:border-l-transparent before:border-r-transparent"
        ></span>
        <div class="selector flex-1 flex">
          <div
            class="select day flex-1 flex flex-col p-0.5 text-grey-700 cursor-default touch-none hover:bg-primary-700 group"
            @wheel="dayWheel"
            @touchstart="fingerStart"
            @touchmove="fingerMoved('day', $event)"
          >
            <a
              @mousedown="up('days')"
              @mouseup="clearInterval"
              class="btn-arrow btn-up cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-up"></fa-icon>
            </a>
            <div class="flex-1 flex flex-col justify-center items-center">
              <span class="text select-none text-xs uppercase leading-3 mb-0.5 group-hover:text-white">{{
                formDayName
              }}</span>
              <span class="num select-none text-[34px] text-grey-700 leading-[34px] group-hover:text-white">{{
                formDay
              }}</span>
            </div>
            <a
              @mousedown="down('days')"
              @mouseup="clearInterval"
              class="btn-arrow btn-down cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-down"></fa-icon>
            </a>
          </div>
          <div
            class="select month flex-1 flex flex-col p-0.5 text-grey-700 cursor-default touch-none hover:bg-primary-700 group"
            @wheel="monthWheel"
            @touchstart="fingerStart"
            @touchmove="fingerMoved('month', $event)"
          >
            <a
              @mousedown="up('months')"
              @mouseup="clearInterval"
              class="btn-arrow btn-up cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-up"></fa-icon>
            </a>
            <div class="flex-1 flex flex-col justify-center items-center">
              <span class="text select-none text-xs uppercase leading-3 mb-0.5 group-hover:text-white">{{
                formMonthName
              }}</span>
              <span class="num select-none text-[34px] text-grey-700 leading-[34px] group-hover:text-white">{{
                formMonth
              }}</span>
            </div>
            <a
              @mousedown="down('months')"
              @mouseup="clearInterval"
              class="btn-arrow btn-down cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-down"></fa-icon>
            </a>
          </div>
          <div
            class="select year flex-1 flex flex-col p-0.5 text-grey-700 cursor-default touch-none hover:bg-primary-700 group"
            @wheel="yearWheel"
            @touchstart="fingerStart"
            @touchmove="fingerMoved('year', $event)"
          >
            <a
              @mousedown="up('year')"
              @mouseup="clearInterval"
              class="btn-arrow btn-up cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-up"></fa-icon>
            </a>
            <div class="flex-1 flex flex-col justify-center items-center">
              <span class="text select-none text-xs uppercase leading-3 mb-0.5 group-hover:text-white">Année</span>
              <span class="num select-none text-[34px] text-grey-700 leading-[34px] group-hover:text-white">{{
                formYear
              }}</span>
            </div>
            <a
              @mousedown="down('years')"
              @mouseup="clearInterval"
              class="btn-arrow btn-down cursor-pointer text-center text-grey-400 w-full h-5"
            >
              <fa-icon icon="angle-down"></fa-icon>
            </a>
          </div>
        </div>
        <div class="buttons mx-auto py-0.5 w-[302px] flex justify-around items-center border-t border-grey-200">
          <a
            @click="hideCalendar"
            class="btn-cancel cursor-pointer w-10 h-10 text-grey-700 text-center text-lg leading-10 hover:bg-grey-100 hover:rounded-full"
          >
            <fa-icon :icon="['far', 'times-circle']"></fa-icon>
          </a>
          <a
            @click="clearDateButton"
            class="btn-del cursor-pointer w-10 h-10 text-danger-600 text-center text-lg leading-10 hover:bg-danger-100 hover:rounded-full"
          >
            <fa-icon :icon="['far', 'trash-alt']"></fa-icon>
          </a>
          <a
            @click="acceptButton"
            class="btn-ok cursor-pointer w-10 h-10 text-primary-800 text-center text-lg leading-10 hover:bg-primary-100 hover:rounded-full"
          >
            <fa-icon icon="check"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment'
import { faAngleUp, faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faAngleUp, faAngleDown, faTimesCircle, faCheck, faTrashAlt)

export default {
  props: {
    value: [Date, String], //value of hidden input in format YYYY-MM-DD
    name: String, //name of validated element
    id: {
      type: String,
      required: true
    }, //id of validated element
    formatDate: {
      type: String,
      default: 'DD MMMM YYYY'
    }, //output format date
    language: String, //language: en, de, etc
    vValidate: Object, //v-validate rules
    inputClass: [String, Object], //class applied to the output element
    placeholder: String, //displayed element placeholder
    dataVvAs: String, //data-vv-as from vee-validate
    max: String, //max calendar date
    min: String //min calendar date
  },
  //inject: ['$validator'],
  data() {
    return {
      calendarOpen: false, //if popup calendar is visible
      maxDate: '', //max popup calendar date
      minDate: '', //min popup calendar date
      outputElement: false, //visible input element - which displays formatted date
      hiddenElement: false, //hidden input element - which contains date in format YYYY-MM-DD
      selectDate: false, //date used in popup calendar
      calendarLanguage: this.language ? this.language : 'en', //errors and dates language
      modelValue: this.value, //value of hidden input, is needed for validation to work
      lastClientY: false, //touchmove event,
      timeout: null,
      interval: null
    }
  },
  inheritAttrs: false,
  watch: {
    value(newVal) {
      this.modelValue = newVal
    }
  },
  created() {
    moment.locale(this.calendarLanguage)

    if (this.max) {
      this.maxDate = moment(this.max)
    }

    if (this.min) {
      this.minDate = moment(this.min)
    }

    this.resetSelectDate()
  },
  mounted() {
    this.outputElement = this.$el.querySelector(`#${this.id}-output`)

    this.hiddenElement = this.$el.querySelector(`#${this.id}`)

    let newTop = this.outputElement.offsetHeight + 12

    this.$el.querySelector('.date-select').style.top = newTop + 'px'
  },
  computed: {
    outputDate() {
      if (this.value) {
        return moment(this.value).format(this.formatDate)
      }

      return ''
    },
    formDay() {
      return this.selectDate.format('DD')
    },
    formMonth() {
      return this.selectDate.format('MM')
    },
    formYear() {
      return this.selectDate.format('YYYY')
    },
    formDayName() {
      return this.selectDate.format('dddd')
    },
    formMonthName() {
      return this.selectDate.format('MMMM')
    }
  },
  methods: {
    fingerStart(e) {
      this.lastClientY = e.touches[0].clientY
    },
    fingerMoved(type, e) {
      if (e.touches[0].clientY > this.lastClientY) {
        if (type == 'month') {
          this.assignSelectDate(moment(this.selectDate).subtract(1, 'months'))
        } else if (type == 'day') {
          this.assignSelectDate(moment(this.selectDate).subtract(1, 'days'))
        } else if (type == 'year') {
          this.assignSelectDate(moment(this.selectDate).subtract(1, 'years'))
        }
      } else if (e.touches[0].clientY < this.lastClientY) {
        if (type == 'month') {
          this.assignSelectDate(moment(this.selectDate).add(1, 'months'))
        } else if (type == 'day') {
          this.assignSelectDate(moment(this.selectDate).add(1, 'days'))
        } else if (type == 'year') {
          this.assignSelectDate(moment(this.selectDate).add(1, 'years'))
        }
      }

      this.lastClientY = e.touches[0].clientY
    },
    addOutsideClickListener() {
      document.addEventListener('click', this.clickOutside, false)
    },
    removeOutsideClickListener() {
      document.removeEventListener('click', this.clickOutside, false)
    },
    clickOutside(e) {
      if (this.$el && !this.$el.contains(e.target)) {
        this.hideCalendar(e)
      }
    },

    inputChangeMobile(e) {
      this.$emit('change', e)

      this.$emit('input', this.$el.value)
    },
    inputChangeDesktop(e) {
      setTimeout(() => {
        this.$emit('change', e)
      }, 0)
    },

    resetSelectDate() {
      if (this.value) {
        this.assignSelectDate(moment(this.value))
      } else {
        this.assignSelectDate(moment())
      }
    },
    assignSelectDate(newDate) {
      if (typeof this.maxDate == 'object' && this.maxDate.isBefore(newDate)) {
        newDate = this.maxDate
      }

      if (typeof this.minDate == 'object' && this.minDate.isAfter(newDate)) {
        newDate = this.minDate
      }

      this.selectDate = newDate
    },

    showCalendar() {
      if (this.calendarOpen) {
        return
      }

      this.addOutsideClickListener()

      this.resetSelectDate()

      this.calendarOpen = true
    },
    hideCalendar(e) {
      e.preventDefault()

      this.removeOutsideClickListener()

      this.calendarOpen = false
    },

    acceptButton(e) {
      this.$emit('input', this.selectDate.format('YYYY-MM-DD'))

      let event
      if (typeof Event === 'function') {
        event = new Event('change')
      } else {
        event = document.createEvent('Event')
        event.initEvent('change', true, true)
      }
      this.hiddenElement.dispatchEvent(event)

      this.hideCalendar(e)
    },
    clearDateButton(e) {
      this.$emit('input', '')

      let event
      if (typeof Event === 'function') {
        event = new Event('change')
      } else {
        event = document.createEvent('Event')
        event.initEvent('change', true, true)
      }
      this.hiddenElement.dispatchEvent(event)

      this.hideCalendar(e)
    },

    clearInterval() {
      clearTimeout(this.timeout)
      clearInterval(this.interval)
    },

    up(type) {
      this.outputElement.focus()
      this.assignSelectDate(moment(this.selectDate).add(1, type))
      this.timeout = setTimeout(() => {
        this.interval = setInterval(() => {
          this.assignSelectDate(moment(this.selectDate).add(1, type))
        }, 100)
      }, 200)
    },

    down(type) {
      this.outputElement.focus()
      this.assignSelectDate(moment(this.selectDate).subtract(1, type))
      this.timeout = setTimeout(() => {
        this.interval = setInterval(() => {
          this.assignSelectDate(moment(this.selectDate).subtract(1, type))
        }, 100)
      }, 200)
    },
    dayWheel(e) {
      if (e.deltaY < 0) {
        this.assignSelectDate(moment(this.selectDate).add(1, 'days'))
      } else if (e.deltaY > 0) {
        this.assignSelectDate(moment(this.selectDate).subtract(1, 'days'))
      }
    },
    monthWheel(e) {
      if (e.deltaY < 0) {
        this.assignSelectDate(moment(this.selectDate).add(1, 'months'))
      } else if (e.deltaY > 0) {
        this.assignSelectDate(moment(this.selectDate).subtract(1, 'months'))
      }
    },
    yearWheel(e) {
      if (e.deltaY < 0) {
        this.assignSelectDate(moment(this.selectDate).add(1, 'years'))
      } else if (e.deltaY > 0) {
        this.assignSelectDate(moment(this.selectDate).subtract(1, 'years'))
      }
    }
  }
}
</script>
