var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"modal-container z-[997] flex flex-col justify-center items-center fixed inset-0"},[_c('div',{staticClass:"overlay absolute inset-0 bg-black/50"}),(_vm.show)?_c('div',{class:[
      'modal flex flex-col z-[997] w-11/12 min-w-[350px] max-w-5xl min-h-[300px] max-h-[95vh] overflow-auto bg-white shadow rounded-b-sm border-t-[6px]',
      {
        'border-primary-500': _vm.type === 'primary',
        'border-accent-500': _vm.type === 'accent',
        'border-info-400': _vm.type === 'info',
        'border-success-400': _vm.type === 'success',
        'border-warning-400': _vm.type === 'warning',
        'border-danger-400': _vm.type === 'danger'
      }
    ]},[_c('div',{staticClass:"body m-12 flex flex-1"},[(_vm.confirm)?_c('div',{class:[
          'icon text-2xl mx-3 rounded-full h-10 flex-shrink-0 flex-grow-0 basis-10 flex justify-center items-center relative z-[998] before:absolute before:z-[997] before:inset-[18%] before:rounded-full',
          {
            'bg-primary-300 before:bg-primary-500': _vm.type === 'primary',
            'bg-accent-300 before:bg-accent-500': _vm.type === 'accent',
            'bg-info-300 before:bg-info-500': _vm.type === 'info',
            'bg-success-300 before:bg-success-500': _vm.type === 'success',
            'bg-warning-300 before:bg-warning-500': _vm.type === 'warning',
            'bg-danger-300 before:bg-danger-500': _vm.type === 'danger'
          }
        ]},[_c('fa-icon',{staticClass:"text-white z-[999]",attrs:{"icon":_vm.icon}})],1):_vm._e(),_c('div',{staticClass:"content flex-1"},[_c('h1',{staticClass:"text-xl text-grey-800 font-medium mt-2"},[_vm._v(_vm._s(_vm.title))]),_vm._t("default")],2)]),(_vm.confirm || _vm.cancel)?_c('div',{staticClass:"actions py-4 px-6 bg-grey-100 text-right space-x-4"},[(_vm.cancel)?_c('button',{staticClass:"cancel-button bg-none border-none outline-none font-semibold text-sm py-1 px-5 min-w-[100px] h-10 rounded cursor-pointer text-grey-800 hover:bg-grey-300",on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.cancelLabel)+" ")]):_vm._e(),(_vm.confirm)?_c('button',{class:[
          'confirm-button bg-none border-none outline-none font-semibold text-sm py-1 px-5 min-w-[100px] h-10 rounded cursor-pointer',
          {
            'bg-primary-100 text-primary-600 hover:bg-primary-200': _vm.type === 'primary',
            'bg-accent-100 text-accent-700 hover:bg-accent-200': _vm.type === 'accent',
            'bg-info-100 text-info-500 hover:bg-info-200': _vm.type === 'info',
            'bg-success-100 text-success-500 hover:bg-success-200': _vm.type === 'success',
            'bg-warning-100 text-warning-500 hover:bg-warning-200': _vm.type === 'warning',
            'bg-danger-100 text-danger-500 hover:bg-danger-200': _vm.type === 'danger'
          }
        ],on:{"click":_vm.onConfirm}},[_vm._v(" "+_vm._s(_vm.confirmLabel)+" ")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }