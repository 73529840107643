<template>
  <div :class="['flex flex-col', { 'animate-shakeError': hasError }]">
    <label :for="`text-field-${name}`" class="uppercase text-sm font-semibold mb-1 flex items-center text-grey-800">
      <span>{{ label }}</span>
      <span v-if="optional" class="text-xs text-grey-600 normal-case">&nbsp;&bull;&nbsp;Optionnel</span>
    </label>
    <div
      :class="[
        'relative flex items-center bg-white py-1 px-3 w-[375px] h-[50px] shadow border-l-[5px] transition-[border,width] duration-300',
        hasError ? 'border-danger-400' : 'border-primary-600',
        { 'border-primary-800 w-[425px]': isFocused }
      ]"
    >
      <input
        class="border-none outline-none bg-transparent text-base ml-2 text-grey-700 flex-1 h-full w-full placeholder:font-light placeholder:text-grey-600 placeholder:text-sm placeholder:uppercase"
        :type="type"
        :name="name"
        :id="`text-field-${name}`"
        :placeholder="placeholder"
        :regex="regex"
        :pattern="regex"
        :step="step"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="
          $emit('focus')
          isFocused = true
        "
        @blur="
          $emit('blur')
          isFocused = false
        "
      />
      <fa-icon v-if="icon" :icon="icon" class="text-xl text-primary-900 ml-2 mr-1"></fa-icon>
    </div>
    <div
      :class="[
        'origin-top-left transition-all duration-300 ease-in-out text-danger-500 py-1 px-5 bg-danger-100 border-l-[5px] border-danger-500 shadow',
        isFocused ? 'max-w-[425px]' : 'max-w-[375px]',
        hasError ? 'text-base mt-5 scale-y-100 py-2' : 'text-[0] scale-y-0'
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import fieldMixin from './mixins/field.js'
export default {
  mixins: [fieldMixin],
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: 'text'
    },
    regex: String,
    step: [String, Number]
  }
}
</script>
