<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      :class="[
        'min-w-[288px] max-w-xl min-h-[48px] max-h-20 py-3.5 px-5 text-base font-semibold flex items-center fixed top-12 right-6 z-[1000] rounded border-2 border-danger-300 shadow',
        {
          'bg-info-100 border-info-300 text-info-600': type === 'info',
          'bg-success-100 border-success-300 text-success-600': type === 'success',
          'bg-warning-100 border-warning-300 text-warning-600': type === 'warning',
          'bg-danger-100 border-danger-300 text-danger-600': type === 'error'
        }
      ]"
      v-if="show"
    >
      <div
        :class="[
          'icon-wrapper mr-2 shrink-0 w-9 h-9 flex items-center justify-center rounded-full',
          ,
          {
            'bg-info-300': type === 'info',
            'bg-success-300': type === 'success',
            'bg-warning-300': type === 'warning',
            'bg-danger-300': type === 'error'
          }
        ]"
      >
        <div
          :class="[
            'icon-background w-3/4 h-3/4 text-center pt-0.5 bg-white rounded-full border-2',
            {
              'border-info-500': type === 'info',
              'border-success-500': type === 'success',
              'border-warning-500': type === 'warning',
              'border-danger-500': type === 'error'
            }
          ]"
        >
          <fa-icon
            :icon="icon"
            :class="[
              'icon text-base',
              {
                'text-info-500': type === 'info',
                'text-success-500': type === 'success',
                'text-warning-500': type === 'warning',
                'text-danger-500': type === 'error'
              }
            ]"
          ></fa-icon>
        </div>
      </div>
      <slot></slot>
    </div>
  </transition>
</template>
<script>
import { faInfo, faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faInfo, faExclamation, faCheck)

export default {
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 5000
    },
    type: {
      type: String,
      default: 'error',
      validator: value => ['success', 'error', 'info', 'warning'].includes(value)
    }
  },
  data() {
    return {
      icons: {
        error: 'exclamation',
        warning: 'exclamation',
        info: 'info',
        success: 'check'
      },
      timeoutId: null
    }
  },
  computed: {
    classes() {
      return ['popup-message', this.type]
    },
    icon() {
      return this.icons[this.type]
    }
  },
  watch: {
    show(isActive) {
      if (isActive) {
        this.setDuration()
      } else {
        this.$emit('change', false)
      }
    }
  },
  methods: {
    setDuration() {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setTimeout(() => {
        this.$emit('change', false)
      }, this.duration)
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.show) {
        this.setDuration()
      }
    })
  }
}
</script>
