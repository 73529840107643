var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['flex flex-col', { 'animate-shakeError': _vm.hasError }]},[_c('label',{staticClass:"uppercase text-sm font-semibold mb-1 flex items-center text-grey-800",attrs:{"for":("text-field-" + _vm.name)}},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.optional)?_c('span',{staticClass:"text-xs text-grey-600 normal-case"},[_vm._v(" • Optionnel")]):_vm._e()]),_c('div',{class:[
      'relative flex items-center bg-white py-1 px-3 w-[375px] h-[50px] shadow border-l-[5px] transition-[border,width] duration-300',
      _vm.hasError ? 'border-danger-400' : 'border-primary-600',
      { 'border-primary-800 w-[425px]': _vm.isFocused }
    ]},[_c('input',{staticClass:"border-none outline-none bg-transparent text-base ml-2 text-grey-700 flex-1 h-full w-full placeholder:font-light placeholder:text-grey-600 placeholder:text-sm placeholder:uppercase",attrs:{"type":_vm.type,"name":_vm.name,"id":("text-field-" + _vm.name),"placeholder":_vm.placeholder,"regex":_vm.regex,"pattern":_vm.regex,"step":_vm.step},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":function($event){_vm.$emit('focus')
        _vm.isFocused = true},"blur":function($event){_vm.$emit('blur')
        _vm.isFocused = false}}}),(_vm.icon)?_c('fa-icon',{staticClass:"text-xl text-primary-900 ml-2 mr-1",attrs:{"icon":_vm.icon}}):_vm._e()],1),_c('div',{class:[
      'origin-top-left transition-all duration-300 ease-in-out text-danger-500 py-1 px-5 bg-danger-100 border-l-[5px] border-danger-500 shadow',
      _vm.isFocused ? 'max-w-[425px]' : 'max-w-[375px]',
      _vm.hasError ? 'text-base mt-5 scale-y-100 py-2' : 'text-[0] scale-y-0'
    ]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }