import moment from 'moment'
moment.locale('fr')

let API_URL = 'http://certiphyto.localhost/v1/'
let CAS_URL = 'https://qualif.auth.agrosupdijon.fr/cas/'
let CLIENT_URL = 'http://localhost:8080/admin'

switch (process.env.NODE_ENV) {
  case 'production':
    API_URL = 'https://api-certiphyto.ead.agrosupdijon.fr/v1/'
    CAS_URL = 'https://auth.agrosupdijon.fr/cas/'
    CLIENT_URL = 'https://inscription-certiphyto.ead.agrosupdijon.fr/admin'
    break
  case 'qualif':
    API_URL = 'https://qualif.api-certiphyto.ead.agrosupdijon.fr/v1/'
    CLIENT_URL = 'https://qualif.inscription-certiphyto.ead.agrosupdijon.fr/admin'
    break
}

export { API_URL, CAS_URL, CLIENT_URL }

export const datatableClasses = {
  table: {
    tableWrapper: '',
    tableHeaderClass: 'mb-0',
    tableBodyClass: 'mb-0',
    tableClass: 'table table-bordered table-hover',
    loadingClass: 'loading',
    ascendingIcon: 'fa fa-sort-up',
    descendingIcon: 'fa fa-sort-down',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'fa fa-sort',
    detailRowClass: 'vuetable-detail-row',
    handleIcon: 'fa fa-bars'
  },
  pagination: {
    wrapperClass: 'flex justify-center font-medium my-4',
    activeClass:
      'text-primary-700 relative before:absolute before:left-1/2 before:-ml-[5px] before:w-[10px] before:h-px before:bg-primary-700 before:top-0 after:absolute after:left-1/2 after:-ml-[5px] after:w-[10px] after:h-px after:bg-primary-700 after:bottom-0.5',
    disabledClass: 'disabled text-grey-100 cursor-not-allowed',
    pageClass: 'cursor-pointer py-1.5 px-3.5',
    linkClass: 'py-1.5 px-3.5 first:pr-1.5 [&:nth-last-child(2)]:pr-1.5 last:pl-1.5 [&:nth-child(2)]:pl-1.5',
    paginationInfoClass: 'float-left',
    dropdownClass: 'form-control'
  }
}

export const states = [
  {
    title: 'Non éligible',
    icon: 'times',
    class: 'not-eligible'
  },
  {
    title: 'Pré-inscrit',
    icon: 'file-signature',
    class: 'pre-registered'
  },
  {
    title: 'Inscrit',
    icon: 'check',
    class: 'registered'
  },
  {
    title: 'Terminé',
    icon: 'graduation-cap',
    class: 'completed'
  },
  {
    title: 'Non terminé',
    icon: 'times',
    class: 'not-completed'
  }
]

export const statesGroupList = [
  {
    id: 0,
    text: 'Tous'
  },
  {
    id: 2,
    text: 'Pré-inscrit'
  },
  {
    id: 3,
    text: 'Inscrit'
  },
  {
    id: 4,
    text: 'Terminé'
  },
  {
    id: 5,
    text: 'Non terminé'
  },
  {
    id: 1,
    text: 'Non éligible'
  }
]

export const pdfStatesGroupList = [
  {
    id: 0,
    text: 'Tous'
  },
  {
    id: 1,
    text: 'Non signé'
  },
  {
    id: 2,
    text: 'Signé'
  },
  {
    id: 3,
    text: 'Validé'
  }
]

export const completionStatesGroupList = [
  {
    id: 0,
    text: 'Tous'
  },
  {
    id: 1,
    text: 'Complet'
  },
  {
    id: 2,
    text: 'Partiel'
  },
  {
    id: 3,
    text: 'Aucun'
  }
]

export const completionStates = {
  done: {
    icon: 'check',
    text: 'Complet'
  },
  partial: {
    icon: 'ellipsis-h',
    text: 'Partiel'
  },
  none: {
    icon: 'times',
    text: 'Aucun'
  }
}

export const levelsList = [
  {
    id: 0,
    text: 'Tous'
  },
  {
    id: 100,
    text: 'DEBUG'
  },
  {
    id: 200,
    text: 'INFO'
  },
  {
    id: 250,
    text: 'NOTICE'
  },
  {
    id: 300,
    text: 'WARNING'
  },
  {
    id: 400,
    text: 'ERROR'
  },
  {
    id: 500,
    text: 'CRITICAL'
  },
  {
    id: 550,
    text: 'ALERT'
  },
  {
    id: 600,
    text: 'EMERGENCY'
  }
]

export const roles = {
  USER: 'user',
  ADMIN: 'admin'
}

export const acl = [
  {
    action: 'delete-pdf',
    roles: [roles.ADMIN]
  },
  {
    action: 'unregister-intern',
    roles: [roles.ADMIN]
  }
]

export const noValueFormatter = val => val || '<span class="no-data">Non renseigné</span>'

export const dateFormatter = date => (date ? moment(date).format('DD/MM/YYYY') : noValueFormatter(date))
export const dateFormatterFull = date => (date ? moment(date).format('DD/MM/YYYY HH:mm:SS') : noValueFormatter(date))
