var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",class:[
    'inline-flex text-white font-semibold text-sm uppercase p-1 min-w-[100px] h-[40px] justify-between items-center shadow focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75',
    {
      'bg-primary-500 hover:bg-primary-400 focus:ring-primary-500': _vm.type === 'primary',
      'bg-accent-600 hover:bg-accent-400 focus:ring-accent-500': _vm.type === 'secondary',
      'bg-info-500 hover:bg-info-400 focus:ring-info-500': _vm.type === 'info',
      'bg-success-500 hover:bg-success-400 focus:ring-success-500': _vm.type === 'success',
      'bg-warning-600 hover:bg-warning-500 focus:ring-warning-500': _vm.type === 'warning',
      'bg-danger-500 hover:bg-danger-400 focus:ring-danger-500': _vm.type === 'error',
      'bg-grey-600 hover:bg-grey-500 focus:ring-grey-500': _vm.type === 'common',
      'cursor-not-allowed': _vm.disabled
    }
  ],attrs:{"type":_vm.buttonType,"href":_vm.link,"target":_vm.newWindow ? '_blank' : null,"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('span',{staticClass:"mx-3"},[_vm._t("default")],2),(_vm.icon)?_c('div',{class:[
      'text-white h-6 w-6 rounded-full text-center font-normal text-base flex items-center justify-center',
      {
        'bg-primary-700': _vm.type === 'primary',
        'bg-accent-900': _vm.type === 'secondary',
        'bg-info-600': _vm.type === 'info',
        'bg-success-600': _vm.type === 'success',
        'bg-warning-700': _vm.type === 'warning',
        'bg-danger-600': _vm.type === 'error',
        'bg-grey-700': _vm.type === 'common'
      }
    ]},[_c('fa-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e(),(_vm.upload)?_c('input',{ref:"upload_file",staticClass:"hidden",attrs:{"type":"file","multiple":_vm.multiUpload,"accept":_vm.uploadAccept},on:{"input":function($event){return _vm.$emit('upload', $event.target.files)}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }