<template>
  <div id="app" class="flex min-h-screen max-w-full">
    <router-view />
    <popup-message v-model="popup.show" :type="popup.type">{{ popup.message }}</popup-message>
  </div>
</template>

<script>
import PopupMessage from '@/components/common/notification/PopupMessage'

export default {
  data() {
    return {
      state: this.$store.state
    }
  },
  computed: {
    popup() {
      return this.state.popupMessage
    }
  },
  components: {
    PopupMessage
  }
}
</script>
