<template>
  <div class="register-container flex-1 flex flex-col lg:flex-row min-h-screen">
    <div class="left flex flex-col items-center order-1 pt-5 pb-12 lg:flex-[2] lg:items-end lg:order-[0]">
      <div class="logos absolute inset-x-0 top-12 text-center lg:static lg:self-start">
        <img
          class="inline-block align-middle max-h-20 ml-4 xs:max-h-24"
          src="/images/logo-vivea.png"
          alt="Logo Vivea"
        />
        <img
          class="inline-block align-middle max-h-20 ml-4 xs:max-h-24"
          src="/images/logo-rf.png"
          alt="Logo République Française"
        />
        <img
          class="inline-block align-middle max-h-20 ml-4 xs:max-h-24"
          src="/images/logo-ead.png"
          alt="Logo L’Institut Agro Enseignement à distance"
        />
      </div>
      <form action="#" class="my-auto mx-0 lg:relative lg:z-[2] lg:left-12 space-y-4">
        <text-field
          v-model="$v.form.last_name.$model"
          name="last_name"
          label="Nom"
          placeholder="Dupont"
          icon="user"
          required
          :has-error="$v.form.last_name.$error || !!errors.last_name"
        >
          <p v-if="!$v.form.last_name.required">Veuillez renseigner un nom</p>
          <p v-if="!$v.form.last_name.minLength">
            Le nom doit comporter au minimum
            {{ $v.form.last_name.$params.minLength.min }} caractères
          </p>
          <p v-if="!!errors.last_name">{{ errors.last_name }}</p>
        </text-field>
        <text-field
          v-model="$v.form.first_name.$model"
          name="first_name"
          label="Prénom"
          placeholder="Stephane"
          :icon="['far', 'user']"
          required
          :has-error="$v.form.first_name.$error || !!errors.first_name"
        >
          <p v-if="!$v.form.first_name.required">
            Veuillez renseigner un prénom
          </p>
          <p v-if="!$v.form.first_name.minLength">
            Le prénom doit comporter au minimum
            {{ $v.form.first_name.$params.minLength.min }} caractères
          </p>
          <p v-if="!!errors.first_name">{{ errors.first_name }}</p>
        </text-field>
        <text-field
          v-model="$v.form.email.$model"
          name="email"
          type="email"
          label="Adresse courriel"
          placeholder="stephane.dupont@domaine.fr"
          icon="at"
          required
          :has-error="$v.form.email.$error || !!errors.email"
        >
          <p v-if="!$v.form.email.required">
            Veuillez renseigner une adresse courriel
          </p>
          <p v-if="!$v.form.email.email">L'adresse courriel n'est pas valide</p>
          <p v-if="!!errors.email">{{ errors.email }}</p>
        </text-field>
        <date-picker
          v-model="$v.form.birthdate.$model"
          id="birthdate"
          name="birthdate"
          label="Date de naissance"
          placeholder="01/01/1960"
          icon="birthday-cake"
          required
          :has-error="$v.form.birthdate.$error || !!errors.birthdate"
        >
          <p v-if="!$v.form.birthdate.required">
            Veuillez sélectionner une date de naissance
          </p>
          <p v-if="!!errors.birthdate">{{ errors.birthdate }}</p>
        </date-picker>
        <text-field
          v-model="$v.form.zipcode.$model"
          name="zipcode"
          label="Code postal"
          placeholder="12345"
          icon="map-pin"
          required
          :has-error="$v.form.zipcode.$error || !!errors.zipcode"
        >
          <p v-if="!$v.form.zipcode.required">
            Veuillez renseigner un code postal
          </p>
          <p v-if="!$v.form.zipcode.regex">Le code postal n'est pas correct</p>
          <p v-if="!!errors.zipcode">{{ errors.zipcode }}</p>
        </text-field>
        <div v-if="loading" class="loading text-center text-grey-800 font-medium space-y-1">
          <fingerprint-spinner v-if="loading" :animation-duration="1500" :size="50" color="#4F9E2D" />
          <p>Vérification auprès de Vivéa</p>
          <p>Cette opération peut durer plusieurs secondes</p>
        </div>
        <btn v-else submit icon="arrow-right" @click.prevent="send">Envoyer ma pré-inscription</btn>
      </form>
    </div>
    <div
      class="right flex flex-col items-center justify-center bg-gradient-to-t from-[#184e68] to-[#57ca85] shadow text-grey-200 min-h-[400px] mt-[200px] px-4 lg:bg-[linear-gradient(135deg,var(--tw-gradient-stops))] lg:flex-[3] lg:h-auto lg:mt-0 lg:shadow-[-4px_0_4px_rgba(0,0,0,0.2)]"
    >
      <h1 class="text-3xl text-center">
        Formulaire de pré-inscription
        <br />« Renouvellement du Certiphyto »
      </h1>
      <div class="text-grey-100 mt-2 space-y-2 max-w-[500px] text-justify">
        <p>
          Remplissez le formulaire pour vous pré-inscrire et confirmer votre éligibilité auprès de Vivéa pour la
          formation à distance <span style="white-space: nowrap">« Renouvellement du Certiphyto »</span>.<br />
          <strong
            >Ce module est accessible uniquement aux Décideurs En Entreprise Non-Soumise à Agrément (DENSA).</strong
          >
        </p>
        <p>
          Vous recevrez un courriel confirmant votre pré-inscription et contenant un lien qui vous permettra de suivre
          l'avancement de votre inscription.
        </p>
        <p class="details text-center">
          <btn class="mt-4" type="secondary" :icon="['fab', 'youtube']" @click.prevent="videoModal = true"
            >Présentation de la formation</btn
          >
          <btn class="mt-4 mb-6" type="secondary" icon="info" @click.prevent="detailsModal = true"
            >Détails de la formation</btn
          >
        </p>
        <p>
          Pour toute question technique, veuillez envoyer un courriel à l'adresse suivante :
        </p>
        <p class="text-center">
          <a class="font-bold" href="mailto:ead-certiphyto@agrosupdijon.fr"
            >e&#97;d-certi&#112;hyt&#111;&#64;a&#103;&#114;o&#115;u&#112;dijon&#46;f&#114;</a
          >
        </p>
      </div>
      <div class="not-eligible bg-white p-4 max-w-[600px] shadow" v-if="not_eligible">
        <p class="text-grey-800 font-semibold">
          Après vérification au sein de la base de données de Vivéa, il s'avère que vous n'êtes pas éligible. Veuillez
          trouver ci-dessous la raison fournie par Vivéa :
        </p>
        <p class="text-danger-400">{{ not_eligible }}</p>
        <p class="text-grey-800 font-semibold">
          Contactez Vivéa au <strong>01 56 33 29 03</strong> pour de plus amples informations.
        </p>
      </div>
    </div>
    <modal v-model="detailsModal" title="Détails de la formation" type="accent" :confirm="false" cancelLabel="Lu 👌">
      <div class="my-2 space-y-4">
        <p>
          Cette formation renouvellement du Certiphyto se déroule entièrement à distance sur une plateforme de formation
          en ligne dont l’adresse web vous sera communiquée par courriel avec des identifiants de connexion dès la
          validation de votre inscription.
        </p>
        <p>Le parcours de formation est composé de 3 modules de formation et de 3 quiz.</p>
        <p>Les 3 modules de formation sont les suivants :</p>
        <ul class="list-disc ml-8">
          <li>Première partie - Bien comprendre</li>
          <li>Deuxième partie - Sécuriser et protéger</li>
          <li>Troisième partie - Traiter</li>
        </ul>
        <p>
          Dans chaque module des animations, des vidéos, des illustrations, des petits exercices de compréhension
          émaillent les contenus écrits et permettent de mieux retenir les informations essentielles à l’utilisation des
          produits phytopharmaceutiques.
        </p>
        <p>Pour valider votre formation vous devrez avoir :</p>
        <ol class="list-decimal ml-8">
          <li>étudié les 3 modules de formation,</li>
          <li>effectué les 3 quiz de validation et obtenu 100 % de bonnes réponses à chacun d’entre-eux.</li>
        </ol>
        <p>Cette formation vous prendra environ 2 heures, selon vos mode et rythme de travail.</p>
        <p>
          À partir de la validation de votre inscription, vous aurez <strong>un mois</strong> devant vous pour suivre et
          terminer votre formation.
        </p>
        <p>Bien cordialement,</p>
        <p>L’équipe Renouvellement du Certiphyto à distance<br />L’Institut Agro Enseignement à distance.</p>
      </div>
    </modal>
    <modal
      v-model="videoModal"
      title="Présentation de la formation"
      type="accent"
      :confirm="false"
      cancelLabel="Fermer"
    >
      <iframe
        class="w-full aspect-video mt-4"
        src="https://www.youtube-nocookie.com/embed/hL9uOMOBFN0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </modal>
  </div>
</template>

<script>
import TextField from '@/components/common/form/TextField'
import Btn from '@/components/common/action/Btn'
import DatePicker from '@/components/common/form/DatePicker'
import Modal from '@/components/common/notification/Modal'

import { required, minLength, email } from 'vuelidate/lib/validators'
import { dateObject, regex } from '@/configs/validators'

import { FingerprintSpinner } from 'epic-spinners'
import { faUser, faAt, faBirthdayCake, faMapPin, faArrowRight, faInfo } from '@fortawesome/free-solid-svg-icons'
import { faUser as faUserAlt } from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faUser, faAt, faBirthdayCake, faMapPin, faArrowRight, faUserAlt, faInfo, faYoutube)

export default {
  data() {
    return {
      loading: false,
      detailsModal: false,
      videoModal: false,
      errors: {},
      not_eligible: null,
      form: {
        last_name: '',
        first_name: '',
        email: '',
        birthdate: null,
        zipcode: ''
      }
    }
  },
  methods: {
    async send() {
      this.$v.$touch()
      this.not_eligible = null
      if (!this.$v.$error) {
        this.loading = true
        const [err, ret] = await this.$http.post('register', this.form)
        this.loading = false
        if (!err) {
          const intern = ret.data.data
          if (intern.is_eligible) {
            this.$router.push({
              name: 'inscription',
              params: { key: ret.data.data.key }
            })
          }
          this.not_eligible = intern.reason_not_eligible
        } else {
          if (err.response.data.error) {
            this.$store.errorMessage(err.response.data.error.message)
          } else {
            Object.entries(err.response.data).forEach(([prop, errs]) => {
              this.errors = { ...this.errors, [prop]: errs[0] }
            })
          }
        }
      } else {
        this.$store.errorMessage('Vous devez remplir tous les champs.')
      }
    }
  },
  components: {
    FingerprintSpinner,
    TextField,
    DatePicker,
    Btn,
    Modal
  },
  validations: {
    form: {
      last_name: {
        required,
        minLength: minLength(2)
      },
      first_name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      birthdate: {
        required,
        dateObject
      },
      zipcode: {
        required,
        regex: regex(`^(([0-8][0-9])|(9[0-8]))[0-9]{3}$`)
      }
    }
  }
}
</script>
<style>
.loading .fingerprint-spinner {
  @apply my-0 mx-auto;
}
.loading p:last-child {
  @apply -mt-2 text-grey-700 text-xs font-normal;
}
</style>
